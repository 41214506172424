<template>
  <view-wrapper title="全国库存">
    <div class="myform p-24px pb-0px" style="min-width: 1284px; background: #fff">
      <form-query :model="form.data" @search="form.search">
        <a-row>
          <a-col :span="5">
            <a-form-item label="配件名称">
              <a-input v-model:value="form.data.name" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="配件编码">
              <a-input v-model:value="form.data.serialNo" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="车品牌">
              <a-input v-model:value="form.data.brand" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="品质类型">
              <e-select
                placeholder="请选择"
                :ajax="{ get: { action: 'GET /common/lookup/IQYQLYTYPE' } }"
                :allowClear="false"
                v-model="form.data.type"
                :props="{ label: 'message', value: 'code' }"
                value-key="id"
              ></e-select>
            </a-form-item>
          </a-col>
          <a-col :span="4" class="flex justify-end">
            <a-button @click="form.init">重置</a-button>
            <!-- <a-button @click="form.search" style="margin-left: 10px" type="primary">查询</a-button> -->
          </a-col>
        </a-row>
      </form-query>
    </div>
    <a-spin :spinning="table.loading">
      <div v-if="table.data.length">
        <ul class="table-block">
          <li class="item-card" v-for="item in table.data" :key="item.id">
            <div class="flex items-center">
              <a-tooltip>
                <span class="inline-block w-200px truncate font-bold">{{ item.name }}</span>
              </a-tooltip>
              <span style="margin-left: 8px">数量: {{ item.count }}</span>
            </div>
            <div class="flex items-center justify-between" style="margin-top: 8px">
              <span>
                <span>{{ item.serialNo }}</span>
                <PictureOutlined
                  v-if="!!item.imgUrl"
                  @click="imgPreview.click(item.imgUrl)"
                  style="color: #0d8d8d; margin-left: 8px"
                />
              </span>
              <span>
                <span>{{ item.brand }}</span>
                <span class="ml-10px">{{ item.type.message }}</span>
              </span>
            </div>
            <div class="flex items-center justify-between" style="margin-top: 22px">
              <span class="flex items-center">
                <span class="font-bold">{{ item.branchName }}</span>
                <span style="margin-left: 8px">{{ item.preparedBy }}</span>
                <a-tooltip :title="item.cellphone">
                  <PhoneFilled
                    class="cursor-pointe"
                    style="color: #0d8d8d; transform: rotateY(180deg); margin-left: 8px"
                  />
                </a-tooltip>
                <MessageFilled
                  v-if="item.chatUser"
                  class="cursor-pointer message-icon"
                  style="color: #ffd100; margin-left: 8px"
                  @click="webim.open(item.chatUser)"
                />
              </span>
              <span>{{ formatMoney(item.price, '￥') }}</span>
            </div>
          </li>
        </ul>
        <div class="flex justify-end" style="margin-top: 30px">
          <a-pagination
            v-model:current="table.paging.pageIndex"
            v-model:pageSize="table.paging.pageSize"
            :total="table.paging.itemCount"
            @change="table.changePageIndex"
          />
        </div>
      </div>
      <div v-else style="background: #fff">
        <a-empty></a-empty>
      </div>
    </a-spin>
  </view-wrapper>
  <a-modal v-model:visible="imgPreview.visible" :footer="null" @cancel="imgPreview.visible = false">
    <div class="mt-20px">
      <img alt="image" style="width: 100%" :src="imgPreview.imgUrl" />
    </div>
  </a-modal>
</template>

<script lang="ts" setup>
import { reactive } from 'vue'
import { useTable, formatMoney, useWebim } from '@vue-mfe/utils'

import {
  Spin as ASpin,
  Pagination as APagination,
  Empty as AEmpty,
  Tooltip as ATooltip,
  Row as ARow,
  Modal as AModal,
  Col as ACol
} from 'ant-design-vue'
import { PhoneFilled, MessageFilled, PictureOutlined } from '@ant-design/icons-vue'

const webim = useWebim()
const form = reactive(
  {
    data: {
      name: '',
      serialNo: '',
      brand: '',
      type: ''
    },
    init () {
      form.data.name = ''
      form.data.serialNo = ''
      form.data.brand = ''
      form.data.type = ''
    },
    search () {
      table.get()
    }
  }
)
const table = useTable(
  {
    ajax: {
      get: {
        action: 'GET /client/sharedinventory',
        params: () => ({ ...form.data })
      }
    },
    columns: [],
    paging: {
      pageIndex: 1,
      pageSize: 15
    },
    changePageIndex (page: number) {
      table.paging.pageIndex = page
      table.get()
    }
  }
)
const imgPreview = reactive(
  {
    visible: false,
    imgUrl: '',
    click (url: string) {
      imgPreview.imgUrl = url
      imgPreview.visible = true
    }
  }
)
</script>

<style scoped>
.table-block {
  display: flex;
  flex-wrap: wrap;
  min-width: 1284px;
}
.item-card {
  width: calc((100% - 48px) / 3);
  min-width: 378px;
  box-sizing: border-box;
  height: 128px;
  padding: 16px 24px;
  background: #fff;
  margin-top: 24px;
  margin-left: 24px;
}
.item-card .message-icon {
  visibility: hidden;
}
.item-card:hover .message-icon {
  visibility: visible;
}
.item-card:nth-child(3n + 1) {
  margin-left: 0;
}
</style>
